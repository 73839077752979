import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/pengs/Projects/xijingge/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "xijingge"
    }}>{`xijingge`}</h1>
    <p>{`using docz.js to build documentation like static site`}</p>
    <p>{`just run 'npm run dev' for dev mode.`}</p>
    <p>{`to create or reorganize the catogory pane for the doc, edit the menu property of the big object in doczrc.js file.
-> to create nested menu, use `}<strong parentName="p">{`menu`}</strong>{`: [{name: bla bla, `}<strong parentName="p">{`menu`}</strong>{`: `}{`[a, b, c, ...]`}{`} , bla, bla ...] structure.  `}</p>
    <p>{`to add new pages, add .mdx file in pages folder. and specify the following info:`}</p>
    <pre><code parentName="pre" {...{}}>{`---
name: 如何读经
route: /  
menu: Home
---
`}</code></pre>
    <p>{` note: menu value must match the menu value in doczrc.js file, or it will create a new nemu bullit for the nav pane.
must have unique route among others.`}</p>
    <p>{`to use react, create .jsx file and import component to .mdx file.`}</p>
    <p>{`to build, run 'npm run build', and you can find the build folder in .docz>dist.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      